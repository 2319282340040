<script>
export default {
  name: 'publikasi-detail',
  data() {
    return {
      post: null
    }
  },
  mounted() {
    this.getDetailPost()
  },
  methods: {
    getDetailPost() {
      this.axios.get(`post/slug/${this.$route.params.slug}`)
        .then( response => {
          this.post = response.data.result.post
          let isViewed = this.saveLastView('post', this.post.id)
          if (isViewed) {
            this.setViewed(this.post.id)
          }
        })
        .catch( () => {
          this.$router.push('/404')
        })
    },
    setViewed(id) {
      this.axios.put(`post/${id}/view`)
      .then( () => {
        //console.log(response)
      })
    },
  }
}
</script>

<template>
  <div v-if="post">
    <div class="head-for-bg mobile-for-head" :style="{ backgroundImage: `url(${$store.getters.backgroundImage})` }">
      <img :src="$store.getters.backgroundImage" class="hide-mobile" alt="" />
      <div class="for-title">
        <h4 class="font-weight-6   font-24 width-50 margin-auto w-res-100 pl-res-10 pr-res-10">{{ post.title }}</h4>
      </div>
    </div>
    <div class="container">
      <div class="_sub-container">
        <div class="content-detail-layanan">
          <img v-if="post.banner" :src="post.banner" alt="">
          <p class="ln-2 mt-2" v-html="post.content"></p>
          <p class="ln-2 mt-2" v-if="post.start_date !== null && post.end_date !== null">
            Acara : {{ post.start_date | date_only }} - {{ post.end_date | date_only }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>